import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`At Dashbot, we understand the importance of enhancing your bots to create the best bot experience, one that continually engages and retains users. The biggest challenge here is understanding how well your bot handles user queries and, more importantly, understanding what messages your bot doesn’t currently handle. This is why we’ve launched our new ‘NotHandled’ report, which you’ll find under your Conversations tab.`}</p>
    <p>{`Dashbot’s NotHandled report enables you to understand how your bot improves over time by showing you how many messages your bot doesn’t handle and what percentage of your overall message volume is not handled over time. As you continually improve your bot and add support for new use cases, these improvements will be reflected in the percentage of NotHandled messages.`}</p>
    <p>{`This report shows you three significant data points:`}</p>
    <ul>
      <li parentName="ul">{`Total messages your bot could not handle (blue)`}</li>
      <li parentName="ul">{`Total users affected by your bot’s inability to respond (black)`}</li>
      <li parentName="ul">{`Percentage of incoming messages that went unhandled (green)`}</li>
    </ul>
    <p>{`Beneath the chart, you’ll find the actual messages that your bot could not respond to and what percentage of those messages were included in your incoming messages. You can then add support for these specific messages to reduce the percentage of NotHandled messages, creating a frictionless bot experience and resulting in fewer frustrated users and higher engagement.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/06/07140406/NotHandledTable-1024x291.png",
        "alt": null
      }}></img></p>
    <p>{`As you continue to improve your bot’s ability to handle every message that comes through this report, the green line should begin trending downward and those messages will start disappearing. Now that you’ve implemented intents, you can take advantage of additional reports such as the Intent Funnel, Top Intents, and `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/2018/05/02/conversation-paths/"
      }}>{`Conversation Paths`}</a>{` to continue to drill into intent activity.`}</p>
    <h4>{`To get started, see our documentation to implement intents:`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.dashbot.io/docs/facebook/not-handled/"
        }}>{`Facebook`}</a>{` (NPM `}{`&`}{` REST)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.dashbot.io/docs/alexa/outgoing-intents/"
        }}>{`Amazon Alexa`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.dashbot.io/docs/google/outgoing-intents/"
        }}>{`Google Assistant`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.dashbot.io/docs/generic/generic-not-handled-intent/"
        }}>{`Generic`}</a>{` (All Platforms)`}</li>
    </ul>
    <p>{`Dashbot is free and takes minutes to integrate so sign up to see how your bot is performing! Contact us if you need help getting started, or would like to get a full demo of our reports and tools: `}<a parentName="p" {...{
        "href": "mailto:contact@dashbot.io"
      }}>{`contact@dashbot.io`}</a>{`.`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.dashbot.io/"
        }}>{`Dashbot`}</a>{` is a conversational analytics platform that enables enterprises and developers to understand user behaviors, optimize response effectiveness, and increase user satisfaction through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including NLP response effectiveness, sentiment analysis, conversational analytics, and the full chat session transcripts.`}</em></p>
    <p><em parentName="p">{`We also have tools to take action on the data, like our live person take over of chat sessions and broadcast messaging for re-engagement.`}</em></p>
    <p><em parentName="p">{`We support Alexa, Google Home, Facebook Messenger, Slack, Twitter, Kik, SMS, web chat, and any other conversational interface.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales/?ref=blog+not+handled"
      }}><br parentName="a"></br>
        {`Contact us for a demo `}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      